import React from 'react'
import { Images } from "../../ThemeConfig/Images";
import './TradingProducts.scss'

export default function TradingProducts() {
  return (
    <div className='TrendingProWrapper'>
        <div className='container'>
            <h2 className="topTitleRight">TGB Merchandise</h2>
            <div className="cardList">
                <div class="card">
                    <img src={Images.productOne} alt="List_Image" className="card-img-top"/>
                    <div class="card-body">
                        <h5 class="card-title">Kawasaki Z900 Rolled up Poster</h5>
                        <div class="d-grid gap-2">
                        <a href="https://tgb-merchandise.blinkstore.in/product/BPD-A0E7-52AB-4B89" target="_blank" class="btn btn-primary" rel="noreferrer">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <img src={Images.productTwo} alt="List_Image" className="card-img-top"/>
                    <div class="card-body">
                        <h5 class="card-title">Unisex Round Neck Half Sleeve</h5>
                        <div class="d-grid gap-2">
                            <a href="https://tgb-merchandise.blinkstore.in/product/BPD-5C61-EC27-4038" target="_blank" class="btn btn-primary" rel="noreferrer">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <img src={Images.productThree} alt="List_Image" className="card-img-top"/>
                    <div class="card-body">
                        <h5 class="card-title">Ceramic Coffee Mug Black</h5>
                        <div class="d-grid gap-2">
                            <a href="https://tgb-merchandise.blinkstore.in/product/BPD-2B5F-0584-4C44" target="_blank" class="btn btn-primary" rel="noreferrer">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <img src={Images.productFour} alt="List_Image" className="card-img-top"/>
                    <div class="card-body">
                        <h5 class="card-title">Female Round Neck Half Sleeve</h5>
                        <div class="d-grid gap-2">
                            <a href="https://tgb-merchandise.blinkstore.in/product/BPD-5142-848C-43B5" target="_blank" class="btn btn-primary" rel="noreferrer">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <img src={Images.productFive} alt="List_Image" className="card-img-top"/>
                    <div class="card-body">
                        <h5 class="card-title">Male Round Neck Full Sleeve</h5>
                        <div class="d-grid gap-2">
                            <a href="https://tgb-merchandise.blinkstore.in/product/BPD-0BA0-0453-4317" target="_blank" class="btn btn-primary" rel="noreferrer">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
