import React from 'react'
import './OurFeature.scss'

export default function OurFeature() {
  return (
    <div className='ourFeaturesWrapper'>
        <div className='featuresInnWrapper'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-4'>
                        <h2 className="topTitleRight">Our Features</h2>
                    </div>
                    <div className='col-12 col-lg-8'>
                        <p className='topTitleLeft'>The Good Biker is emerging as the largest, strongest, and most trusted AI-powered network of motorcycle riders, integrating cutting-edge technology to connect, enhance, and revolutionize the riding experience like one big family.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}
