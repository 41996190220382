import React, { useEffect, useState } from 'react';
import { Images } from "../../ThemeConfig/Images";
import { Footerlogo } from "../../components/Icons";
import { Link } from "react-router-dom";
import './Footer.scss'

const Footer = () => {
  const [currentYear, setCurrentYear] = useState();
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  },[]);
  return (
    <div className='footerwrapper'>
      <div className='container'>
        <div className='footerwrapper__info'>
          <div className='logoImage'>
            <div className='logoImage__img'>
              <span className='logo'><Footerlogo/></span>
              <div className="startupIndia">
              <span className='india'><img src={Images.startupIndia} alt="footerLogo" className='img-fluid'/></span>
                <ul className="logoListText">
                  <li>Revolutionizing</li>
                  <li>Motorcycling and</li>
                  <li>Motorsports Ecosystem</li>
                  <li>through Startup India</li>
                  <li>initiative.</li>
                </ul>
              </div>
             
            </div>
          </div>
          <div className="linknlistss">
             <ul>
               <li><Link to="/">Home</Link></li>
               <li><Link to="/sitemap">Sitemap</Link></li>
               <li><Link to="#">Blogs</Link></li>
            </ul>
        </div>
        <div className="linknlistss">
             <ul>
               <li><Link to="/policy">Privacy Policy</Link></li>
               <li><Link to="/terms">Terms & Conditions</Link></li>
               <li><Link to="/faq">FAQs</Link></li>
            </ul>
        </div>
          <div className='appBtns'>
            <h3>Get The App</h3>
            <Link to='https://apps.apple.com/us/app/the-good-biker/id6448780305' target='_blank'><img src={Images.appstore} alt="footerLogo" className='img-fluid'/></Link>
            
            <Link to='https://play.google.com/store/apps/details?id=com.thegoodbiker.userapp' target='_blank'><img src={Images.googleplay} alt="footerLogo" className='img-fluid'/></Link>
          </div>
        </div>
        <div className='footerwrapper__copyright'>
          <div className='infoText'>
            <p>© {currentYear} The Good Biker. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;