import React from 'react'
import './DoRide.scss'

export default function DoRide() {
  return (
    <div className='doRideWrapper'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-lg-12'>
                    <div className='doRideContent'>
                        <div className='row'>
                            <div className='col-12 col-lg-6'>
                                <h2 className='title'>Do The <span>Ride</span> thing.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>      
  )
}
