import React from 'react'
import './WorryFree.scss';

export default function WorryFree() {
  return (
    <div className='worryFreeWrapper'>
        <div className='container'>
            <h2 className="topTitleRight">TGB AMC</h2>
            <div className='row'>
                <div className='col-12 col-lg-12'>
                    <div className='worryFreeContent'>
                        <div className="worryContentLeft">
                            <h2 className="title">Experience worry-free riding with TGB AMC</h2>
                            <p className="info">Your ultimate Annual Maintenance Contract for motorcycles. Enjoy personalized care, transparent pricing, and the convenience of choosing the best  garage and the best consumables for your bike, all in one place.</p>
                            <p className="info">TGB AMC ensures your motorcycle stays in top condition while offering a hassle-free experience, empowering bikers to "Do The Ride Thing."</p>
                        </div>
                        <div className="worryContentRight">
                            <div className="contentWrap">
                                <h4 className="title">Transparent Process:</h4>
                                <p className="info">Complete visibility on service done on your motorcycle.</p>
                            </div>
                            <div className="contentWrap">
                                <h4 className="title">Transparent Pricing:</h4>
                                <p className="info">Know the exact cost of services upfront.</p>
                            </div>
                            <div className="contentWrap">
                                <h4 className="title">Centralized Digital Records:</h4>
                                <p className="info">Access your bike's entire service history digitally anytime, anywhere.</p>
                            </div>
                            <div className="contentWrap">
                                <h4 className="title">Cost-Effective Solutions:</h4>
                                <p className="info">Save money with bundled services and periodic maintenance plans.</p>
                            </div>
                            <div className="contentWrap">
                                <h4 className="title">Flexible Consumable Options:</h4>
                                <p className="info">Choose between stock or premium consumables (engine oil, brake fluid, coolant, brake pads, etc.) based on your preference and budget.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
