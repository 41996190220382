import React from 'react';
import { Constants } from './Constants';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Carousel.scss'


const options = {
  margin:40,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay:true,
  loop:true,
  autoplaySpeed:2000,
  navSpeed:2000,
  autoplayHoverPause:true,
  responsive:{
    0:{
      items:1,
      nav: false,
    },
    600:{
      items:1,
      nav: false,
    },
    1000:{
      items:1,
    },
  },
}

const Carousel = () => {
  return (
    <div className='bannerWrapperNew'>
      <ReactOwlCarousel className='owl-theme carouselBanner' {...options}>
      {
        Constants?.bannerSlider?.map((data) => (
          <div className='itemCover' key={data?.id}>
            <div className='item'>
              <h2>{data?.title}</h2>
            </div>
          </div>
        ))
      }
      </ReactOwlCarousel>
    </div>
  );
};

export default Carousel;