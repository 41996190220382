import React from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Carousel from '../Carousel/Carousel';
import Features from '../Features/Features';
import Footer from '../Footer/Footer';
import Wingman from '../Wingman/Wingman';
import WorryFree from '../WorryFree/WorryFree';
import GetInTouch from '../GetInTouch/GetInTouch';
import DoRide from '../DoRide/DoRide';
import Explore from '../Explore/Explore';
import OurFeature from '../OurFeature/OurFeature';
import TradingProducts from '../TradingProducts/TradingProducts';

const Home = () => {
  return (
    <div className='featuresWrapper'>
      <div className='featuresInnWrapper'>
        <Navemenu/>
        <Carousel/>
        <OurFeature />
        <Features/>
        <Wingman />
        <TradingProducts />
        <WorryFree />
        <Explore />
        <GetInTouch />
        <DoRide />
        {/* <RidersSaying/> */}
        <Footer/>
      </div>
    </div>
  );
};

export default Home;