import { Images } from "../../ThemeConfig/Images";

export const Constants = {
  featuresSlider:[
    {
      id:'1',
      title:'Social Community',
      discription:"Connect with riders worldwide on TGB's Social Community. Share experiences, join discussions in the Tech Forum, explore insightful Blogs, and bond with fellow bikers over shared passions and adventures.",
      image: `${Images?.featuresOne}`,
    },
    {
      id:'2',
      title:'Digital Garage',
      discription:"In sync with TGB AMC, effortlessly manage your motorcycles with the Digital Garage, while the TGB Sensor Suite provides real-time performance insights through OBD2, GPS, and TPMS for smarter, more connected biking.",
      image: `${Images?.featuresTwo}`,
    },
    {
      id:'3',
      title:'Club Management',
      discription:"Connect, organize, and ride with ease using TGB Clubs. Manage your biker group with ride planning, event scheduling, member chats, and more—all in one seamless platform.",
      image: `${Images?.featuresThree}`,
    },
    {
      id:'4',
      title:'Marketplace',
      discription:"Explore the TGB Marketplace—your ultimate destination for buying, selling, and discovering motorcycles, accessories, and gears. Secure, user-friendly, and tailored for passionate riders. Start your ride shopping today!",
      image: `${Images?.featuresFour}`,
    },
    {
      id:'5',
      title:'Ride & Learn',
      discription:'Dive into exciting biking events, earn prestigious certifications, and revel in thrilling competitions. Never forget to "DO THE RIDE THING!"',
      image: `${Images?.featuresFive}`,
    },
  ]
}