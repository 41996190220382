import React from 'react'
import './Wingman.scss';

export default function Wingman() {
  return (
    <div className='wingmanWrapper'>
        <div className='container'>
            <h2 className="topTitleRight">Our AI Assistance</h2>
            <div className='row'>
                <div className='col-12 col-lg-12'>
                  <div className='wingContentWrap'>
                    <div className="wingContent">
                      <div className="innerInfo">
                        <p className="wingText"><span>WINGMAN</span> - Wise Intelligent Navigation & Guide for Motorcycling
                        Assistance and Networking</p>
                        <h2 className="wingTitle">World's First AI assistant for bikers.</h2>
                        <div className="badgesList">
                          <span class="badge customBadge">Ride Intel</span> 
                          <span class="badge customBadge">Guidance & Maintenance Network</span> 
                          <span class="badge customBadge">Navigation</span> 
                        </div>
                        <p className="detailsInfo">Delivers predictive maintenance, personalized ride insights, and real-time solutions, simplifying bike care and enhancing every journey. Coming soon on the TGB app.</p>
                        <a href="https://chatgpt.com/g/g-aYpqAI6jn-the-good-biker" target="_blank" rel="noreferrer" class="btn btn-primary customBtn">Explore The Good Biker MyGPT >> </a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}
