import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import './Terms.scss';
import { Helmet } from 'react-helmet';

const Terms = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='cmsPagesWrapper'>
    <Navemenu/>
    <Helmet>
      <meta charSet='utf-8'/>
      <title>Terms and Conditions - The Good Biker</title>
      <meta name='discription' content='Check information about Terms and Conditions of The Good Biker.'/>
      <meta name='keywords' content='TGB Terms and Conditions' />
    </Helmet>
    <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
        <div className="staticInfo">
          <h2>TERMS & CONDITIONS</h2>
          <h4>Effective date: September 11, 2022</h4>
          <h4>Last updated on: January 10, 2025</h4>

          <p>The Good Biker App and Website Owner, including subsidiaries and affiliates (“App” or “Mobile app” or “Mobile app owner” or “Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the app or website or any of the pages comprising the app or  website (“app” or “website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these app or website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the app or website.</p>
          <p>Welcome to our app or website. If you continue to browse and use this app or website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern The Good Biker's relationship with you in relation to this website.</p>
          <p>The term 'The Good Biker' or 'us' or 'we' refers to the owner of the website whose registered/operational office is 34 & 35, Ananda Reddy Layout, Shanthi Pura, Electronic City Phase 2 Bengaluru KARNATAKA 560100. The term 'you' refers to the user or viewer of our app or website.</p>
          <p>The use of this app or website is subject to the following terms of use:</p>
          <ul>
            <li>The content of the pages of this app or website is for your general information and use only. It is subject to change without notice.</li>
            <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this app or website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law</li>
            <li>Your use of any information or materials on this app or website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this app or website meet your specific requirements.</li>
            <li>This app or website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
            <li>All trademarks reproduced in this app or website which are not the property of, or licensed to, the operator are acknowledged on the app or website.</li>
            <li>Unauthorized use of this app or website may give rise to a claim for damages and/or be a criminal offense.</li>
            <li>From time to time this app or website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
            <li>You may not create a link to this app or website from another website or document without The Good Biker’s prior written consent.</li>
            <li>Your use of this app or website and any dispute arising out of such use of the app or website is subject to the laws of Karnataka or other regulatory authority.</li>
          </ul>
          <p>We as a service provider shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>

          <h2>RIDE TERMS & CONDITIONS</h2>
          <h4>Effective date: September 11, 2022</h4>
          <h4>Last updated on: January 10, 2025</h4>

          <p>In order to ensure the safety of all road users, motorcyclists must adhere to the same road rules as other vehicles. In addition, there are some specific road safety rules that only apply to motorcyclists. Following these rules is extremely necessary and important. If a rider is found NOT following the rules, he/she will be banned from continuing the ride and from all future rides. No refund will be processed in such an event.</p>
          <h4>WEARING A HELMET</h4>
          <p>All motorcyclists and pillions are required by law to wear an ISI-marked motorcycle helmet that is securely fastened while riding. Not wearing a helmet while riding a motorcycle not only makes you vulnerable to a serious death-threatening injury but is an offense that can result in a fine.</p>
          <h4>DRIVING LICENSE & OTHER DOCUMENTS</h4>
          <p>All motorcyclists are required to carry their driving licenses, vehicle insurance, and pollution-under-control certificates.</p>

          <h4>RIDING BETWEEN LANES</h4>
          <p>Motorcyclists are not permitted to ride between lanes or rows of slow-moving or stationary vehicles.</p>

          <h4>RIDING ON THE FOOTPATH</h4>
          <p>Motorcyclists are not permitted to ride on footpaths.</p>
          <h4>SPLITTING LANES</h4>
          <p>Motorcyclists are not permitted to split lanes. This means riding in between vehicles traveling in the same direction, even if there is enough space.</p>
          <h4>PASSING EMERGENCY VEHICLES</h4>
          <p>When passing a stationary emergency vehicle that has its red and blue lights flashing, motorcyclists must slow down to 40 km/h or less. This rule applies to all emergency vehicles, including police cars, fire trucks, and ambulances.</p>
          <h4>CHILDREN</h4>
          <p>Children & teenagers under the age of 18 are not allowed on the rides. If you don't have a driving license, you can't join our rides.</p>
          <h4>SPEED LIMIT</h4>
          <p>Motorcyclists are subject to the speed limits as set by the government or the transport department.</p>
          <h4>RIDING SIDE BY SIDE</h4>
          <p>Motorcyclists are not allowed to ride side by side except when overtaking or when traffic conditions make it essential.</p>
          <h4>USE OF MOBILE PHONES</h4>
          <p>Using mobile phones while riding a motorcycle is not allowed.</p>
          <h4>LIGHTS</h4>
          <p>Motorcyclists must use lights at night and in poor visibility.</p>
          <h4>ACCIDENT OR DEATH</h4>
          <p>In an unfortunate event of an accident, injury, or death, The Good Biker or any entity or person related to it cannot be held responsible. All riders are required to follow all traffic rules, wear proper armor and helmet, and ride under the speed limit.</p>
          <h4>RIDING STYLE AND FORMATION</h4>
          <p>All riders and pillions are required to attend the briefing given by the road captain. Riders must follow the riding style and ride formation as explained and make full use of hand and leg signals.</p>

      </div>
      </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Terms;