import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import './Policy.scss';
import { Helmet } from 'react-helmet';

const Policy = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='cmsPagesWrapper'>
    <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Privacy Policy - The Good Biker</title>
        <meta name='discription' content='Check information about privacy policy of The Good Biker.'/>
        <meta name='keywords' content='TGB Privacy Policy' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
                <h2>Privacy Policy</h2>
                <h4>Effective date: September 11, 2022</h4>
                <h4>Last updated on: January 10, 2025</h4>
                <p>This privacy policy sets out how TGB Technologies Pvt. Ltd. hereinafter referred to as The Good Biker uses and protects any information that you give The Good Biker when you use this app or website.</p>
                <p>The Good Biker is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this app or website, and then you can be assured that it will only be used in accordance with this privacy statement.</p>
                <p>The Good Biker may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
                <h4>We may collect the following information:</h4>
                <ul>
                  <li>Name and gender</li>
                  <li>Contact information including email address, phone number, and address</li>
                  <li>Vehicle information including VIN & registration number</li>
                  <li>Demographic information such as postcode, preferences and interests</li>
                  <li>Photo and media metadata such as timestamps and location if you upload media on TGB app.</li>
                  <li>Details about your interactions with the app, device specifications, and crash reports help us improve your experience.</li>
                  <li>Other information relevant to customer surveys and/or offers</li>
                </ul>
                <p>What we do with the information we gather</p>
                <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                <ul>
                  <li>Internal record keeping.</li>
                  <li>We may use the information to improve our products and services.</li>
                  <li>We may periodically send promotional emails about new services, special offers or other
                    information which we think you may find interesting using the email address which you have
                    provided</li>
                  <li>From time to time, we may also use your information to contact you for market research
                  purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the app or website according to your interests.
                  </li>
                </ul>

                <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in suitable measures</p>
                <h4>How we use cookies</h4>
                <p>A cookie is a small file which asks permission to be placed on your mobile or computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                <p>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web traffic and improve our app and website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                <p>Overall, cookies help us provide you with a better app and website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your mobile or computer or any information about you, other than the data you choose to share with us.</p>
                <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>

                <h4>Controlling your personal information</h4>
                <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                <ul>
                  <li>whenever you are asked to fill in a form on the app or website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
                  <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at <a href="mailto:hello@thegoodbiker.com">hello@thegoodbiker.com</a></li>
                  <li>You can disable GPS tracking, but this may limit the app’s functionality.</li>
                </ul>
                <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
                <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above email address. We will promptly correct any information found to be incorrect.</p>

                <h2>CANCELLATION & REFUND POLICY</h2> 
                <h4>Effective date: September 11, 2022</h4>
                <h4>Last updated on: January 10, 2025</h4>
                <p>The Good Biker believes in helping its customers as far as possible and has therefore a liberal cancellation and refund policy. Under this policy:</p>
                <ul>
                  <li>Cancellations for the event bookings will be considered only if the request is made within 2 days of placing the order. In this case, a full refund will be initiated. However, the cancellation request may not be entertained if the bookings have been communicated to the vendors/merchants and they have initiated the process.</li>
                  <li>The Good Biker does not accept cancellation or refund requests for paid participation in online challenges and events.</li>
                  <li>If you feel that the service/product received is not as shown on the app or website or as per your expectations, you must bring it to the notice of our customer service within 2 days of receiving the service. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                  <li>In case of complaints regarding services/products that come with a warranty from our 3rd party organizers/vendors/manufacturers, please refer the issue to them directly.</li>
                  <li>In case of complaints regarding services that come with a warranty from our 3rd party organizers, please refer the issue to them directly.</li>
                  <li>In case of any Refunds approved by The Good Biker, it’ll take 6-8 days for the refund to be processed to the end customer.</li>
                </ul>
                <h2>SHIPPING POLICY</h2>
                <h4>Effective date: September 11, 2022</h4>
                <h4>Last updated on: January 10, 2025</h4>
                <p>Shipping services for this business are facilitated through third-party shipping and courier providers. For detailed information regarding delivery terms and conditions, please refer to the respective Shipping Policies of the service providers</p>

                <h2>Contact</h2>
                <ul>
                  <li><strong>Merchant Legal entity name: </strong>TGB TECHNOLOGIES PRIVATE LIMITED</li>
                  <li><strong>Registered Address: </strong>Karishma Building, Unit 201, 34-35 Ananda Reddy Layout, Shanthipura, Electronic City Phase 2, Bengaluru, Karnataka, PIN: 560100</li>
                  <li><strong>Operational Address: </strong>Karishma Building, Unit 201, 34-35 Ananda Reddy Layout, Shanthipura, Electronic City Phase 2, Bengaluru, Karnataka, PIN: 560100</li>
                  <li><strong>Telephone No: </strong><a href="tel:+918310740069" className="contactLink">+91-83107-40069</a></li>
                  <li><strong>E-Mail ID: </strong><a href="mailto:hello@thegoodbiker.com" className="contactMail">hello@thegoodbiker.com</a></li>
                </ul>
              </div>
        </div>
        </div>
      </div>
    <Footer/>
    </div>
  );
};

export default Policy;