import React from 'react'
import { Images } from "../../ThemeConfig/Images";
import './Explore.scss'
import { Link } from 'react-router-dom';

export default function Explore() {
  return (
    <div className="downloadAppWrapper">
            <div className='container'>
            <h2 className="topTitleRight">Download the App</h2>
                <div className='row'>
                     <div className='col-12 col-lg-12'>
                                <div className='appWrapper'>
                                  <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                      <div className='appWrapper__right'>
                                        <h2>Explore The Good Biker <br /> Mobile App</h2>
                                        <p>Join the revolution of motorcycling with The Good Biker—a thriving community that unites riders across regions into one unstoppable family. Discover rides, connect with enthusiasts, and gear up for endless adventures.</p>
                                        <div className='appBtns'>
                                           <Link to='https://apps.apple.com/us/app/the-good-biker/id6448780305' target='_blank'><img src={Images.appstore} alt="footerLogo" className='img-fluid'/></Link>      
                                            <Link to='https://play.google.com/store/apps/details?id=com.thegoodbiker.userapp' target='_blank'><img src={Images.googleplay} alt="footerLogo" className='img-fluid'/></Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                    <div className='appWrapper__left'>
                                      <div className='innerImage'>&nbsp;</div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                </div>
            </div>
        </div>
  )
}
