import React from 'react';
import { Constants } from './Constants';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Features.scss';

const options = {
  margin:20,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay:true,
  loop:true,
  autoplaySpeed:2000,
  navSpeed:2000,
  autoplayHoverPause:true,
  responsive:{
    0:{
      items:1,
      nav: false,
      dots: false,
    },
    600:{
      items:2,
      nav: false,
      dots: false,
    },
    1000:{
      items:3,
    },
  },
}


const Features = () => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className='xyz'>
              <ReactOwlCarousel className='owl-theme carouselFeatures' {...options}>
              {
                Constants?.featuresSlider?.map((data) => (
                  <div className='itemCover' key={data?.id}>
                    <div className='item'>
                      <div className='bannerImg'>
                        <img src={data?.image} alt={data?.title ?? ''} className='image'/>
                        <div className="middle">&nbsp;</div>
                      </div>
                      <div className='bannerText'>
                        <h2 className="featuresTitle">{data.title}</h2>
                        <p className="featuresText">{data.discription} </p>
                      </div>
                    </div>
                  </div>
                ))
              }
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;