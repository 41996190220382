import React, { useState, useEffect } from 'react';
import { FacebookIcon, InstagramIcon, YoutubeIcon, LinkedinIcon, Logo, SpotifyPodcasts } from "../../components/Icons";
import { Link } from "react-router-dom";
import './Navemenu.scss'

const Navemenu = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Function to handle scroll events
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
  <div className='customNavmenu'>
  <nav className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'scrolled' : ''}`}>
  <div className="container-fluid">
  <div className='navMenuInner'>
    <div className='navLogo'>
      <Link to="/"><Logo/></Link>
    </div>
    <div className="socialLinks">
      <ul>
        <li><Link to='https://www.facebook.com/thegoodbiker/' target='_blank'><FacebookIcon/></Link></li>
        <li><Link to='https://www.instagram.com/iamthegoodbiker/' target='_blank'><InstagramIcon/></Link></li>
        <li><Link to='https://www.youtube.com/channel/UCiqcV_lTuE29iBEiWs2OUlg' target='_blank'><YoutubeIcon/></Link></li>
        <li><Link to='https://in.linkedin.com/company/the-good-biker' target='_blank'><LinkedinIcon/></Link></li>
        <li><Link to='https://open.spotify.com/show/4IPN128j5CrhdssZve54Yy?si=VvobFlr7Q3iEp-6v5PT4eA&nd=1&dlsi=0f7b92f6f64a431b' target='_blank'><SpotifyPodcasts/></Link></li>
      </ul>
    </div>
  </div>
  </div>
  </nav>
</div>
  );
};

export default Navemenu;