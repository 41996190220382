import React from 'react'
import { Images } from '../../ThemeConfig/Images'
import './GetInTouch.scss'

export default function GetInTouch() {
  return (
    <div className='inTouchWrapper'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-lg-12'>
                    <div className="inTouchContent">
                        <div className="mapSide">
                            <div className="mapPopup">
                                <img src={Images.logoPng} alt="logo" className="logoMapImg"/>
                                <h4 className="title">TGB Technologies Pvt. Ltd.</h4>
                                <p className="subTitle">Do The Ride thing.</p>
                                <a  href="https://www.google.com/maps/place/Karishma+Apartments/@12.8503274,77.6795321,17z/data=!4m6!3m5!1s0x3bae6dabd2fa40bf:0xca97bf87cb258a95!8m2!3d12.8503065!4d77.6814418!16s%2Fg%2F11j79vq4l3?entry=ttu&g_ep=EgoyMDI1MDMwMy4wIKXMDSoASAFQAw%3D%3D" target="_blank"  className="link">Open Google Maps <img src={Images.arrowImage} alt="arrow"/></a>
                            </div>
                        </div>
                        <div className="formSide">
                            <h2 className="formTitle">Contact Us</h2>
                            <p className="formSubTitle">TGB Technologies Pvt. Ltd.</p>
                            <p className="addressText">Unit 201, 34 & 35, Shanthi Pura, Electronic City phase 2, Bengaluru, KA 560100</p>
                            <a href="https://www.google.com/maps/place/Karishma+Apartments/@12.8503274,77.6795321,17z/data=!4m6!3m5!1s0x3bae6dabd2fa40bf:0xca97bf87cb258a95!8m2!3d12.8503065!4d77.6814418!16s%2Fg%2F11j79vq4l3?entry=ttu&g_ep=EgoyMDI1MDMwMy4wIKXMDSoASAFQAw%3D%3D" target="_blank" className="getDirectBtn">Get Direction<img src={Images.navigateArrow} alt="navArrow" /></a>
                            <div className="contactInfo">
                                <a href="mailto:hello@thegoodbiker.com" className="contactMail">hello@thegoodbiker.com</a>
                                <a href="tel:+918310740069" className="contactLink">+91-83107-40069</a>
                            </div>
                            {/* <div className="socialLinks">
                                <p className="title">Connect With Social</p>
                                <ul className="socialList">
                                    <li className="items">
                                        <a href="https://www.instagram.com/iamthegoodbiker/" target="_blank"><img src={Images.instagram} alt="social Link" /></a>
                                    </li>
                                    <li  className="items">
                                        <a href="https://www.facebook.com/thegoodbiker/" target="_blank"><img src={Images.facebook} alt="social Link" /></a>
                                    </li>
                                    <li  className="items">
                                        <a href="https://api.whatsapp.com/send?phone=9711871488"  target="_blank"><img src={Images.whatsapp} alt="social Link" /></a>
                                    </li>
                                    <li  className="items">
                                        <a href="https://www.youtube.com/channel/UCiqcV_lTuE29iBEiWs2OUlg" target="_blank"><img src={Images.youtube} alt="social Link" /></a>
                                    </li>
                                    <li  className="items">
                                        <a href="https://in.linkedin.com/company/the-good-biker" target="_blank"><img src={Images.linkedin} alt="social Link" /></a>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <form class="row g-3">
                                <div class="col-md-6">
                                    <label for="firstName" class="form-label">First Name</label>
                                    <input type="text" class="form-control" id="firstName" placeholder="Your first name"/>
                                </div>
                                <div class="col-md-6">
                                    <label for="lastName" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" id="lastName" placeholder="Your last name"/>
                                </div>
                                <div class="col-md-12">
                                    <label for="inputEmail4" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="you@company.com"/>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-12">
                                        <label for="countryCode">Phone number</label>
                                            <div className="countryNumber">
                                            <div class="col-md-2">
                                            <select class="form-control selectInput" id="countryCode">
                                                <option value="+1">USA +1</option>
                                                <option value="+44">UK +44</option>
                                                <option value="+91">IN +91</option>
                                                <option value="+61">AUS +61</option>
                                            </select>
                                            </div>
                                            <div class="col-md-10">
                                            <input type="text" class="form-control numberInput" id="phoneNumber" placeholder="Enter phone number" />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="exampleFormControlTextarea1" class="form-label">How can we help?</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Tell us a little about the project..."></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-primary withFull">Send Message</button>
                                </div>
                            </form> */}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>         
  )
}
